<template>
    <el-dialog width="530px" top="40px" title="Export" :before-close="closeModal" :visible.sync="visible" :close-on-click-modal="false" @closed="resetModalState" @open="modalOpen">
        <div class="py-8 px-16">
            <el-checkbox v-model="customDate" label="Anpassat datum" class="mb-4" />
            <el-date-picker v-model="selectedDateRange" type="daterange" :disabled="!customDate" />
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary">Stäng</el-button>
            <el-button @click="exportFile" type="primary">Export</el-button>
        </span>
    </el-dialog>
</template>
<script>
import moment from "moment";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
            default: "",
        },
        financialYear: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            customDate: false,
            selectedDateRange: [],
        };
    },

    methods: {
        modalOpen() {
            this.selectedDateRange = [this.financialYear?.startDate, this.financialYear?.endDate];
        },

        exportFile() {
            this.closeModal();
            const dates = "?startDate=" + moment(this.selectedDateRange[0]).format("YYYY-MM-DD") + "&endDate=" + moment(this.selectedDateRange[1]).format("YYYY-MM-DD");

            const token = "&token=" + localStorage.getItem("token");
            const base = this.axios.defaults.baseURL;
            const url = `/clients/${this.clientId}/bookingFinancialYears/${this.financialYear.financialYearId}/export`;
            console.log(base + url + dates);
            window.location.href = base + url + dates + token;
        },

        closeModal() {
            this.$emit("close");
        },

        resetModalState() {
            this.customDate = false;
            this.selectedDateRange = [moment(), moment().add(1, "year")];
        },
    },
};
</script>
